<template>
  <textarea id="keditorContainer" v-model="value" />
</template>

<script>
import axios from "axios";

export default {
  name: "CommKeditor",
  props: {
    selector: {
      type: String,
      default: "#keditorContainer",
    },
    height: {
      type: String,
      default: "306px",
    },
    content: {
      type: String,
      default: "",
    },
  },
  computed: {
    value: {
      get() {
        if (window.tinymce.editors && window.tinymce.editors[0]) {
          window.tinymce.editors[0].setContent(this.unEscapeHtml(this.content));
        }
        return this.content;
      },
      set(value) {
        this.$emit("update:content", value);
      },
    },
  },
  watch: {
    // content: function (newValue, oldValue) {
    //   console.log('');
    //   window.tinymce.editors[0].setContent(newValue)
    // }
  },
  mounted() {
    // console.log(window.tinymce)
    const vue = this;
    if (window.tinymce.editors.length === 1) {
      try {
        window.tinymce.remove();
      } catch (e) {
        console.log(e);
      }
      window.tinymce.editors.pop();
    }
    window.tinymce.init({
      menubar: false,
      statusbar: false,
      toolbar: false,
      browser_spellcheck: true,
      height: this.height,
      resize: false,
      entity_encoding: "raw",
      forced_root_block: "",
      force_br_newlines: true,
      force_p_newlines: false,
      convert_newlines_to_brs: false,
      body_class: "content p_no_margin", // 에디터 body node에 들어갈 class
      content_css: [
        "/aprvl/static/plugins/keditor/0.4.0/style/editor-content.css", // 에디터 영역에 들어갈 CSS
      ],
      selector: this.selector, // 에디터 iframe로 바뀌게될 container
      mobile: {
        // 모바일웹에서 모바일 테마를 사용할때 사용하는 옵션
        theme: "mobileClassic",
        plugins:
          "kData maps kPaste klink kImage advlist table simpleTable video colorPalette tip-quotation klink",
        toolbar:
          "fontHeading undo publish styleselect bold underline italic kImage video colorPalette fontsizeselect align klink fontStyle",
        ui: ["undo publish", "fontHeading", "kImage video align klink fontStyle"],
      },
      theme: "classic", // 사용할 테마 balloon(상단에 툴바가 없는) 테마도 있으며 아직 개발이 완전하지 않음
      // plugins: 'kData maps kPaste klink kImage advlist table video colorPalette', // 사용할 플러그인 리스트
      plugins: "kData kPaste klink kImage advlist table colorPalette", // 사용할 플러그인 리스트
      toolbar: "bold underline strikethrough colorPalette klink | kImage video maps", // 툴바에 표시할 플러그인
      convert_urls: false,
      relative_urls: false,
      formats: {
        alignleft: { selector: "p,h1,h2,h3,h4,h5,h6,td", styles: { textAlign: "left" } },
        aligncenter: { selector: "p,h1,h2,h3,h4,h5,h6,td", styles: { textAlign: "center" } },
        alignright: { selector: "p,h1,h2,h3,h4,h5,h6,td", styles: { textAlign: "right" } },
        alignjustify: { selector: "p,h1,h2,h3,h4,h5,h6,td", styles: { textAlign: "justify" } },
        bold: { inline: "b" },
        italic: { inline: "i" },
        underline: { inline: "u" },
        strikethrough: { inline: "s" },
      },
      valid_children:
        "p[span|b|u|i|s|a|#text|br],-h2[img|div|figure|b],figcaption[#text],figure[img|figcaption|br|a],+span[u|s],-div[#text]",
      extended_valid_elements:
        "b/strong,s/strike,s/del,i/em,iframe[mapdata|src|id|width|height|frameborder|scrolling|data-*]",
      maps: {
        attach_map_url: "/callback/map", // 카카오 플러스맵 팝업에서 콜백을 받을 url(콜백 스크립트는 아래 '지도 callback 함수' 참고
        map_type: "basic",
      },
      kImage: {
        // upload_handler: this.makeUploadHandler('/api/uploadFile', 'file'), // 서비스에서 지원하는 이미지 업로드 URL 생성하여 사용, 결과 형식은 아래 '이미지 및 동영상 업로드 결과 형식' 참고
        upload_url: "/api/uploadKeditorImage", // 서비스에서 제공 하는 multipart file 업로드 지원 URL 등록(upload_handler, upload_url 중 택일해서 사용)
        max_size: 10 * 1024 * 1024,
        max_count: 50, // 한번에 첨부할수 있는 이미지 개수
        is_caption: true, // 이미지의 캡션 사용 여부
        is_grid_layout: true, // 이미지의 그리드 레이아웃 사용여부
        toolbar:
          "edit resize | widthContent alignCenter alignLeft alignRight floatLeft floatRight representative", // 이미지의 컨텍스트 툴바에 노출하고자 하는 버튼과 액션 정의
        representative_image_callback: function (data) {
          // 대표이미지 설정버튼 노출 및 버튼 클릭시 실행하는 callback함수 정의
          console.log("대표 이미지 데이터 전달", data);
        },
        upload_callback: function (info) {
          // 이미지 업로드의 시작과 끝나는 시점에 호출되는 callback함수 정의
          console.log(info); // info = { status: 'start'|'end', plugin: 'kImage'}
        },
      },
      video: {
        upload_handler: this.makeUploadHandler("/api/uploadVideo", "video"), // 서비스에서 지원하는 동영상 업로드 URL 생성하여 사용, 결과 형식은 아래 '이미지 및 동영상 업로드 결과 형식' 참고
      },
      kPaste: {
        valid_elements: [
          "P",
          "IMG",
          "H1",
          "H2",
          "H3",
          "TABLE",
          "TBODY",
          "TR",
          "TD",
          "B",
          "S",
          "U",
          "A",
        ],
        extended_valid_elements: { STRONG: "b", STRIKE: "s", DEL: "s" },
        invalid_table_children: ["TABLE", "IMG", "FIGURE"],
        valid_attributes: ["href", "src", "rowspan", "colspan"],
      },
      colorPalette: {
        module: ["preset"], // 'preset', 'palette', 'code'
        color_map_preset: ["#BBBBBB", "#333333", "#00BCB4", "#EC2D79", "#5756D0"],
        preset_rows: 1,
        preset_cols: 5,
        default_color: "#333333",
      },
      klink: {
        is_panel: true,
      },
      hrList: [
        { icon: "hr-style1", attributes: { "data-ke-style": "style1" } },
        { icon: "hr-style2", attributes: { "data-ke-style": "style2" } },
        { icon: "hr-style3", attributes: { "data-ke-style": "style3" } },
        { icon: "hr-style4", attributes: { "data-ke-style": "style4" } },
        { icon: "hr-style5", attributes: { "data-ke-style": "style5" } },
        { icon: "hr-style6", attributes: { "data-ke-style": "style6" } },
        { icon: "hr-style7", attributes: { "data-ke-style": "style7" } },
        { icon: "hr-style8", attributes: { "data-ke-style": "style8" } },
      ],
      language: "ko_KR",
      setup: function (ed) {
        ed.on("focusout", function (e) {
          vue.value = ed.getContent();
        });
      },
    });
  },
  methods: {
    makeUploadHandler(url, formname) {
      return function (file, uploadCallback, progressHandler) {
        this.uploadHandler(url, formname, file, uploadCallback, progressHandler);
      };
    },
    uploadHandler(uploadUrl, formname, file, uploadCallback, progressHandler) {
      console.log("UploadHandler called");
      var data = new FormData();
      data.append(formname, file);
      var config = {
        onUploadProgress: function (e) {
          console.log("onUploadProgress called", e.loaded, e.total);
          if (progressHandler && typeof progressHandler === "function") {
            progressHandler(e.loaded, e.total);
          }
        },
      };
      axios
        .post(uploadUrl, data, config)
        .then(function (res) {
          return res.data;
        })
        .then(function (result) {
          uploadCallback(result);
        })
        .catch(function (res) {
          uploadCallback(res.data);
          console.error("image upload error", res);
        });
    },
    unEscapeHtml(str) {
      if (!str) {
        return false;
      }
      return str
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"');
    },
  },
};
</script>
